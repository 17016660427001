import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {can} from '../setups/constant'
import {useAuth} from '../modules/auth'

const PrivateRoutes = () => {
  const auth = useAuth()
  const user = auth.currentUser
  const Employee = lazy(() => import('../pages/employee'))
  const Biodata = lazy(() => import('../pages/nonasn/biodata'))
  const HistoryJabatan = lazy(() => import('../pages/nonasn/history-jabatan'))
  const AddBiodata = lazy(() => import('../pages/nonasn/biodata/NewData'))
  const UpdateBiodata = lazy(() => import('../pages/nonasn/biodata/UpdateData'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const MUsersPage = lazy(() => import('../pages/users'))
  const Account = lazy(() => import('../pages/users/Account'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/nonasn/biodata' />} />
        {/* Pages */}
        {/* Lazy Modules */}
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='nonasn/biodata'
          element={
            <SuspensedView>
              <Biodata />
            </SuspensedView>
          }
        />
        <Route
          path='nonasn/biodata/:nik/edit'
          element={
            <SuspensedView>
              <UpdateBiodata />
            </SuspensedView>
          }
        />
        {can('Administrator', user) ? (
          <Route
            path='nonasn/biodata/add'
            element={
              <SuspensedView>
                <AddBiodata />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        <Route
          path='nonasn/biodata/history-jabatan/:nik'
          element={
            <SuspensedView>
              <HistoryJabatan />
            </SuspensedView>
          }
        />
        <Route
          path='employee/*'
          element={
            <SuspensedView>
              <Employee />
            </SuspensedView>
          }
        />
        {can('Administrator', user) ? (
          <Route
            path='users'
            element={
              <SuspensedView>
                <MUsersPage />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        <Route
          path='account'
          element={
            <SuspensedView>
              <Account />
            </SuspensedView>
          }
        />
        {/* Masters */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
