/* eslint-disable react/jsx-no-target-blank */
import {useAuth} from '../../../../app/modules/auth'
import {AsideMenuItem} from './AsideMenuItem'
import {can} from '../../../../app/setups/constant'
import {Fragment} from 'react'

export function AsideMenuMain() {
  const auth = useAuth()
  const user = auth.currentUser

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>NON ASN</span>
        </div>
        <AsideMenuItem
          to='/nonasn/biodata'
          icon='/media/icons/duotune/general/gen020.svg'
          title='Biodata'
        />
      </div>

      {can('Administrator', user) ? (
        <Fragment>
          <div className='menu-item'>
            <div className='menu-content pt-8 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
            </div>
          </div>
          <AsideMenuItem
            to='users'
            icon='/media/icons/duotune/communication/com006.svg'
            title='Users'
          />
        </Fragment>
      ) : (
        ''
      )}
    </>
  )
}
