import Swal, {SweetAlertOptions} from 'sweetalert2'
import {UserModel} from '../modules/auth'

const toastOpt: SweetAlertOptions = {
  toast: true,
  icon: 'info',
  timer: 3000,
  timerProgressBar: true,
  showConfirmButton: false,
  position: 'top-right',
}

const can = (permission: string, user: UserModel | undefined): boolean =>
  (user?.permissions || []).find((p) => p === permission) ? true : false
const MSwal: SweetAlertOptions = {
  customClass: {
    confirmButton: 'btn-primary',
  },
}

const err422 = (ed: any) => {
  let htm = ''
  Object.keys(ed.errors).map((key, index) => {
    let val = ed.errors[key]
    htm += '<div class="border-bottom-dashed border-gray-300">'
    htm += '<b>' + key + '</b> <ul><li>' + val.join('</li><li>') + '</li></ul>'
    htm += '</div>'
    return 1
  })
  htm += '</tbody></table>'
  Swal.fire({
    title: ed.message,
    icon: 'error',
    html: htm,
  })
}
const logError = (ed: any) => {
  console.log(ed)
  Swal.fire('Error!', 'Check logs for detail', 'error')
}
const errCatch = (ed: any) => {
  if (ed.response.status) {
    if (ed.response.status === 422) {
      err422(ed.response.data)
    } else {
      logError(ed)
    }
  } else {
    logError(ed)
  }
}
function printDiv(divName: string) {
  var declaration = '<!DOCTYPE html>'
  var printContents = document.getElementById(divName)!.innerHTML
  var originalContents = document.documentElement.innerHTML

  var wd = window.open()
  wd?.document.write(declaration + '<html>' + originalContents + '</html>')
  wd!.document.body.innerHTML = printContents
  wd?.print()
  wd?.close()
}

export {toastOpt, can, MSwal, err422, errCatch, printDiv}
